import { FC, useEffect, useMemo, useState } from "react";
import Confetti from "react-confetti";
import Modal from "../../components/Modal";
import ShipmentResume from "./ShipmentResume";
import ShipmentFormList from "./ShipmentFormList";
import PaymentList from "../../components/Payment/PaymentList";
import PaymentTotal from "../../components/Payment/PaymentTotal";
import HorizontalPadding from "../../components/HorizontalPadding";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ShipmentOwner from "../../components/Shipment/ShipmentOwner";
import { currencyExchange, useCurrencyExchanges } from "../../utils";
import ShipmentCreateClientModal from "../../components/Account/CreateClientModal";
import { UserInterface } from "../../interfaces/UserInterface";
import ShipmentTable, {
  ShipmentField,
} from "../../components/Shipment/ShipmentTable";
import {
  ExclamationTriangleIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/20/solid";
import {
  consultBC,
  sendC2PBC,
  sendB2PBC,
  payMerchant,
  saveDocument,
  saveShipment,
  alertService,
  loaderService,
  saveProofOfPayment,
  savePayment,
  updateDocumentStatus,
  getDocument,
  updatePayment,
  annulationMerchant,
  getVtid,
  mercantilChange,
  mercantilC2P,
  mercantilMobilePaymentSearch,
  mercantilTransferSearch,
  updateShipment,
  updateShipmentStatus,
  getAvailableCoupons,
  getShipmentRates,
  redeemCoupon,
  updateDocumentAccountBillTo,
  ReintegrationRequest,
  deleteFileDO,
  verifyAccountBlackList,
} from "../../services";
import {
  BackButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Buttons";
import {
  ShipmentCreatePhase,
  clearShipmentCreate,
  setShipmentCreateList,
  setShipmentCreateOwner,
  setShipmentCreatePhase,
  setShipmentCreateTaxes,
  setShipmentCreateDocument,
  setShipmentCreateDrafts,
  setShipmentCreateCoupon,
} from "../../store/slices";
import {
  PaymentMode,
  TaxInterface,
  PaymentInterface,
  DocumentInterface,
  ShipmentInterface,
  ShipmentStatus,
  DocumentStatus,
  Undefinable,
  PaymentMethodEnum,
  DocumentType,
  PaymentStatusEnum,
  ShipmentService,
  DeliveryType,
  AccountInterface,
  BankAccountInterface,
} from "../../interfaces";
import { CouponDTO } from "../../interfaces/Dtos/CouponDTO";
import classNames from "classnames";
import moment from "moment-timezone";

const ShipmentCreate: FC<{}> = () => {
  const dispatch = useAppDispatch();
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user.user);
  const userBU = useAppSelector((state) => state.user.businessUnit);
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const [vtid, setVtid] = useState<string>();
  const loading = loaderService.useIsLoading();
  const [openCoupon50GIF, setOpenCoupon50GIF] = useState(false);
  const [openCoupon100GIF, setOpenCoupon100GIF] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openCreationModal, setOpenCreationModal] = useState(false);
  const coupon = useAppSelector((state) => state.shipmentCreate.coupon);
  const taxes = useAppSelector((state) => state.shipmentCreate.taxes);
  const owner = useAppSelector((state) => state.shipmentCreate.owner);
  const phase = useAppSelector((state) => state.shipmentCreate.phase);
  const businessUnit = useAppSelector((state) => state.user.businessUnit);
  const document = useAppSelector((state) => state.shipmentCreate.document);
  const payments = useAppSelector((state) => state.shipmentCreate.payments);
  const shipments = useAppSelector((state) => state.shipmentCreate.shipments);
  const drafts = useAppSelector((state) => state.shipmentCreate.shipmentDrafts);

  const items = useMemo(() => {
    return shipments
      .map((shipment) => shipment?.items)
      .reduce((acc, curr) => [...acc, ...curr], []);
  }, [shipments]);

  const subTotal = useMemo(() => {
    return +shipments
      .reduce((acc, shipment) => {
        return (
          acc +
          (shipment?.items.reduce((acc, item) => acc + item.rate.value, 0) ?? 0)
        );
      }, 0)
      .toFixed(2);
  }, [shipments]);

  const ipostel = useMemo(() => {
    return (
      document?.ipostel ??
      document?.taxes.find((t) => t.name === "Ipostel")?.value ??
      shipments.reduce((acc, shipment) => {
        return (
          acc + shipment.items.reduce((acc, item) => acc + item.rate.ipostel, 0)
        );
      }, 0)
    );
  }, [shipments, document?.ipostel, document?.taxes]);

  const iva = useMemo(() => {
    return shipments.reduce((acc, shipment) => {
      return acc + shipment.items.reduce((acc, item) => acc + item.rate.iva, 0);
    }, 0);
  }, [shipments]);

  const total = useMemo(() => {
    return +(
      subTotal +
      taxes?.reduce((acc, tax) => {
        if (tax.type === "percentage") {
          return acc + subTotal * (tax.value / 100);
        }
        return acc + tax.value;
      }, 0)
    ).toFixed(2);
  }, [subTotal, taxes]);

  const remaining = useMemo(() => {
    const result =
      document?.balanceAmount !== undefined
        ? document?.balanceAmount -
          payments
            .filter((p) => p.status === PaymentStatusEnum.PENDING)
            .reduce((acc, payment) => acc + payment.amount, 0)
        : +(
            total -
            payments
              .filter(
                (p) =>
                  p.status !== PaymentStatusEnum.CANCELED &&
                  p.status !== PaymentStatusEnum.REJECT
              )
              .reduce((acc, payment) => acc + payment.amount, 0)
          ).toFixed(2);
    return result;
  }, [total, payments, document]);

  const paymentMode = useMemo(() => {
    return shipments[0]?.paymentMode;
  }, [shipments]);

  const onSave = async (): Promise<Undefinable<DocumentInterface>> => {
    // If there are a document, don't save it again
    if (!!document?.documentID) {
      return document;
    }

    const status = total < 0.01 ? DocumentStatus.PAID : DocumentStatus.PENDING;

    // Save document
    const documentData: DocumentInterface = {
      documentID: "",
      accountOwner: owner,
      taxes,
      paymentMode: paymentMode!,
      subTotal,
      total,
      status,
      shipments: shipments,
      payments: [],
      printed: false,
      hasRetention: false, // document.payments.some((p) => p.isRetention)
      igtfAmount: 0,
      balanceAmount: total,
      documentType: DocumentType.ORDER,
    };

    if (
      paymentMode === PaymentMode.COD ||
      paymentMode === PaymentMode.CREDIT ||
      paymentMode === PaymentMode.BOXOFFICE_CREDIT ||
      remaining < 0.01
    ) {
      // Update shipments
      const updatedShipments: ShipmentInterface[] = [];

      for (const shipment of shipments) {
        const response = await updateShipmentStatus(
          ShipmentStatus.ACTIVE,
          shipment.id,
          shipment.shipmentNumber,
          user ?? undefined
        );

        if (!response || response.didError || !response.model) {
          alertService.error(
            `Error al actualizar la guía`,
            response?.errorMessage
          );
          return;
        } else {
          updatedShipments.push({
            ...shipment,
            status: ShipmentStatus.ACTIVE,
          });
        }
      }

      documentData.shipments = updatedShipments;

      if (
        paymentMode === PaymentMode.COD ||
        paymentMode === PaymentMode.CREDIT
      ) {
        return documentData;
      }
    }

    const response = await saveDocument(
      documentData,
      userBU?.code,
      user ?? undefined,
      owner,
      coupon?.couponType.couponDescription,
      applicationID,
    );
    if (response.didError || !response.model?.id_solicitud) {
      alertService.error(`Error al guardar la orden`, response.errorMessage);
      loaderService.stop();
      return;
    }

    const newDocument = {
      ...documentData,
      documentID: response.model.id_solicitud,
    };

    const documentType =
      status === DocumentStatus.PAID ||
      paymentMode === PaymentMode.BOXOFFICE_CREDIT
        ? DocumentType.INVOICE
        : DocumentType.ORDER;

    if (documentType === DocumentType.INVOICE) {
      const response = await updateDocumentStatus(
        newDocument.documentID,
        status,
        documentType,
        user ?? undefined,
        status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
        DocumentType.ORDER
      );

      if (!!response.didError) {
        alertService.error(
          "Hubo un error actualizando el estado de la orden",
          response.errorMessage
        );
      } else {
        newDocument.status = status;
        newDocument.documentType = documentType;
      }
    }

    loaderService.stop();

    return await updateDocument(newDocument);
  };

  const updateDocument = async (document?: DocumentInterface) => {
    if (!document?.documentID) {
      return;
    }

    loaderService.start();

    const documentResponse = await getDocument(document.documentID);

    if (!documentResponse) {
      loaderService.stop();
      return undefined;
    }

    let updatedDocument = {
      ...document!,
      documentNumber: documentResponse.documentNumber,
      payments: documentResponse.payments,
      total: documentResponse.total,
      status: documentResponse.status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    loaderService.stop();
    dispatch(setShipmentCreateDocument(updatedDocument));

    return updatedDocument;
  };

  const onPay = async (
    payment: PaymentInterface,
    avoidVerifications?: boolean
  ) => {
    const paymentVerified =
      avoidVerifications || user?.roleName === "Superadministrador";
    let result = {
      error: false,
      message: "",
    };

    loaderService.start();
    let savedDocument;

    if (document?.documentID) {
      savedDocument = document;
    } else {
      savedDocument = await onSave();
      if (!savedDocument) {
        loaderService.stop();
        return {
          error: true,
          message: "Error al crear el documento",
        };
      }
    }

    if (
      paymentVerified &&
      payment.paymentMethod.paymentMethodID !== PaymentMethodEnum.MERCHANT
    ) {
      payment.paymentMethod = {
        ...payment.paymentMethod,
        paymentMethodID: Math.abs(payment.paymentMethod.paymentMethodID),
      };
    } else if (
      paymentVerified &&
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.MERCHANT
    ) {
      payment.paymentMethod = {
        ...payment.paymentMethod,
        paymentMethodID: PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO,
      };
    }

    // Merchant payment
    else if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.MERCHANT ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_P2C ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO
    ) {
      let ci = payment.clientIdentifier!;
      if (isNaN(parseInt(ci[0]))) {
        ci = ci.slice(1);
      }

      payment.paymentMethod.paymentMethodID =
        PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO;
      payment.paymentMethod.bankID = 27;
      payment.paymentMethod.currencyID = 1;

      const res = await payMerchant(
        currencyExchange(payment.amount, exchanges, "BS"),
        ci
      );

      if (!res || res.codRespuesta !== "00") {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.mensajeRespuesta ?? "No hubo respuesta de Merchant",
        };
      } else {
        if (res.tipoProducto !== "P2C") {
          payment.paymentMethod.paymentMethodID =
            PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO;
          payment.paymentMethod.bankID = 27;
          payment.reference = `${res?.numSeq ?? ""}:${
            res?.terminalVirtual ?? ""
          }:${res?.numeroAutorizacion}`;
          payment.status = PaymentStatusEnum.PENDING;
        } else {
          payment.paymentMethod.paymentMethodID =
            PaymentMethodEnum.PAGO_MERCHANT_P2C;
          payment.paymentMethod.bankID = 4;
          payment.reference = res?.numeroReferencia ?? "";
        }
      }
    }

    // P2C payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_P2C &&
      payment.destBankID === 6
    ) {
      const res = await consultBC(
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.phone!,
        payment.reference!,
        "PM",
        ""
      );

      if (!res?.success) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,

          message: !!res?.message
            ? res.message.code + " " + res.message.message
            : "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // P2C payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_P2C &&
      payment.destBankID === 4
    ) {
      const res = await mercantilMobilePaymentSearch(
        payment.phone!,
        moment(payment.paymentDate).toDate(),
        payment.reference!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.attempts ?? 1
      );

      if (res?.didError || res.model?.transaction_list?.length === 0) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else if (
        res.model?.transaction_list &&
        res.model?.transaction_list?.length > 0
      ) {
        payment.reference = res?.model?.transaction_list[0].payment_reference;
      }
    }

    // TRF payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
      payment.destBankID === 6
    ) {
      const res = await consultBC(
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        "",
        payment.reference!,
        "TRF",
        ""
      );

      if (!res?.success) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,

          message: !!res?.message
            ? res.message.code + " " + res.message.message
            : "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // TRF payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
      payment.destBankID === 4
    ) {
      const res = await mercantilTransferSearch(
        payment.clientIdentifier!,
        moment(payment.paymentDate).toDate(),
        payment.bank!,
        payment.reference!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.attempts ?? 1
      );

      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.errorList && res.model?.errorList.length > 0) {
          errorMessage = res.model?.errorList
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      }
      if (
        res.model?.transferSearchList &&
        res.model?.transferSearchList?.length > 0
      ) {
        if (res.model?.transferSearchList[0].trxStatus === "R") {
          payment.status = PaymentStatusEnum.REJECT;
          result = {
            error: true,
            message: "La transferencia fue rechazada por el banco",
          };
        } else if (
          res.model?.transferSearchList[0].previouslySearched === "S"
        ) {
          payment.status = PaymentStatusEnum.REJECT;
          result = {
            error: true,
            message: "El pago ya fue verificado previamente",
          };
        }
      }
    }

    // C2P payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_C2P &&
      payment.destBankID === 6
    ) {
      const res = await sendC2PBC(
        payment.bank!,
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.phone!,
        payment.otp
      );

      if (res?.codigoError !== 0) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.descripcionError ?? "Error al procesar el pago",
        };
      }
      payment.reference = res?.secuencial.toString();
    }

    // C2P payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_C2P &&
      payment.destBankID === 4
    ) {
      const res = await mercantilC2P(
        payment.phone!,
        payment.clientIdentifier!,
        payment.otp!,
        savedDocument?.documentID!.split("-")[0]!,
        payment.amount,
        payment.bank!
      );
      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else {
        payment.reference = `${res?.model?.transaction_c2p_response?.payment_reference}:${res?.model?.transaction_c2p_response?.invoice_number}`;
      }
    }

    // POS payment
    else if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.PUNTO_DE_VENTA
    ) {
      payment.reference = `${payment.affiliateNumber}:${payment.batchNumber}`;
    }

    // B2P change payment - Bancaribe
    if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 6
    ) {
      const res = await sendB2PBC(
        payment.bank!,
        payment.clientIdentifier!,
        payment.amount,
        payment.phone!,
        document ? `Asociado a orden #${document.documentID}` : "Pago 1 B2P",
        "Factura"
      );

      if (res?.codigoError !== 0) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.descripcionError ?? "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // B2P change payment - Mercantil
    if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 4
    ) {
      const res = await mercantilChange(
        payment.phone!,
        payment.clientIdentifier!,
        document?.documentID!.split("-")[0]!,
        payment.amount,
        payment.bank!
      );
      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else {
        payment.reference = `${res?.model?.transaction_c2p_response?.payment_reference}:${res?.model?.transaction_c2p_response?.invoice_number}`;
      }
    }

    // Reintegration
    if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.REINTEGRO &&
      payment.status === PaymentStatusEnum.PENDING
    ) {
      const res = await ReintegrationRequest(
        user?.userLogin!,
        businessUnit!,
        document!,
        payment.amount,
        document?.accountOwner!
      );

      payment.paymentMethod.bankAccountID = undefined;

      if (res.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,

          message: res.errorMessage,
        };
      }
    }

    // verifies that the payment was not verified before
    let verified = false;
    if (
      payment.status !== PaymentStatusEnum.REJECT &&
      payment.paymentMethod.paymentMethodID !== PaymentMethodEnum.EFECTIVO &&
      !paymentVerified
    )
      document?.payments.forEach((p) => {
        if (
          p.reference === payment.reference &&
          p.reference !== "" &&
          (p.status === PaymentStatusEnum.APPROVE ||
            p.status === PaymentStatusEnum.PENDING)
        ) {
          verified = true;
        }
      });

    if (verified) {
      payment.status = PaymentStatusEnum.REJECT;
      result = {
        error: true,
        message: "El pago ya fue verificado previamente",
      };
    }

    // Convert amount to negative if change
    if (
      [
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
        PaymentMethodEnum.VUELTO_EFECTIVO,
        PaymentMethodEnum.REINTEGRO,
      ].includes(payment.paymentMethod.paymentMethodID)
    ) {
      payment.amount = -payment.amount;
      payment.paymentAmount = -(payment.paymentAmount ?? 0);
    }

    // Change currency
    let currencyId = payment.paymentMethod.currencyID;

    const response = await savePayment(
      payment,
      savedDocument!,
      userBU?.code,
      user ?? undefined,
      currencyId
    );
    if (response.didError || !response.model) {
      loaderService.stop();
      alertService.error("Error al guardar el pago", response.errorMessage);
      return {
        error: true,
        message: "Error al guardar el pago",
      };
    }
    if (payment.status === PaymentStatusEnum.PENDING) {
      alertService.success(
        "El pago se registró exitosamente",
        "Deberá ser conciliado por Casa Matriz",
        { autoCloseDelay: 15000 }
      );
    }

    // Update document
    await updateDocument(savedDocument);

    loaderService.stop();
    dispatch(setShipmentCreatePhase(ShipmentCreatePhase.PAYMENT));

    return result;
  };

  const onCancelRetention = async (retention: PaymentInterface) => {
    loaderService.start();
    const response = await updatePayment({
      documentID: document?.documentID ?? retention.documentID!,
      paymentDetailID: retention.paymentDetailID!,
      statusID: PaymentStatusEnum.CANCELED,
      user: user!,
    });

    if (!!response.didError) {
      alertService.error(
        "Error al cancelar la retención",
        response.errorMessage
      );
      loaderService.stop();
      return;
    }

    const documentResponse = await getDocument(document!.documentID);
    if (!documentResponse) {
      loaderService.stop();
      return;
    }

    const totalPaid = documentResponse.payments
      .filter(
        (p) =>
          p.status !== PaymentStatusEnum.CANCELED &&
          p.status !== PaymentStatusEnum.REJECT &&
          p.status !== PaymentStatusEnum.PENDING
      )
      .reduce((acc, payment) => acc + payment.amount, 0);
    const remaining = total - totalPaid;
    const status =
      (documentResponse.balanceAmount ?? remaining) < 0.01
        ? DocumentStatus.PAID
        : DocumentStatus.PARTIAL_PAID;

    let updatedDocument = {
      ...document!,
      payments: documentResponse.payments,
      total: documentResponse.total ?? total,
      status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    // Update document status
    if (document?.status !== status) {
      const response = await updateDocumentStatus(
        updatedDocument.documentID,
        status,
        undefined,
        user ?? undefined,
        status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
        updatedDocument.documentType
      );
      if (!!response.didError) {
        alertService.error(
          "Hubo un error actualizando el estado de la orden",
          response.errorMessage
        );
      } else {
        updatedDocument.status = status;
      }
    }

    loaderService.stop();
    dispatch(setShipmentCreateDocument(updatedDocument));
    dispatch(setShipmentCreatePhase(ShipmentCreatePhase.PAYMENT));
  };

  const onCancelPayment = async (transaction: PaymentInterface) => {
    loaderService.start();

    if (vtid === null) {
      alertService.error("No se pudo obtener el VTID");
      loaderService.stop();
      return;
    }

    if (transaction.reference) {
      const paymentVtid = transaction.reference.split(":")[1];

      if (paymentVtid === vtid) {
        const numSeq = transaction.reference.split(":")[0];
        const res = await annulationMerchant(numSeq);

        if (res !== null && res.codRespuesta === "00") {
          const response = await updatePayment({
            documentID: document?.documentID ?? transaction.documentID!,
            paymentDetailID: transaction.paymentDetailID!,
            statusID: PaymentStatusEnum.CANCELED,
            user: user!,
          });

          if (response.didError) {
            alertService.error(
              "Error al cancelar el pago",
              response.errorMessage
            );
            loaderService.stop();
            return;
          }
          alertService.success("El pago se anuló exitosamente");

          const documentResponse = await getDocument(document!.documentID);
          if (!documentResponse) {
            alertService.error("Error al obtener la orden");
            loaderService.stop();
            return;
          }

          const totalPaid = documentResponse.payments
            .filter(
              (p) =>
                p.status !== PaymentStatusEnum.CANCELED &&
                p.status !== PaymentStatusEnum.REJECT &&
                p.status !== PaymentStatusEnum.PENDING
            )
            .reduce((acc, payment) => acc + payment.amount, 0);
          const remaining = total - totalPaid;
          const status =
            (documentResponse.balanceAmount ?? remaining) < 0.01
              ? DocumentStatus.PAID
              : DocumentStatus.PARTIAL_PAID;

          let updatedDocument = {
            ...document!,
            payments: documentResponse.payments,
            total: documentResponse.total ?? total,
            status,
            balanceAmount: documentResponse.balanceAmount,
            baseBalanceAmount: documentResponse.baseBalanceAmount,
          };

          // Update document status
          if (document?.status !== status) {
            const response = await updateDocumentStatus(
              updatedDocument.documentID,
              status,
              undefined,
              user ?? undefined,
              status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
              updatedDocument.documentType
            );
            if (response.didError) {
              alertService.error(
                "Hubo un error actualizando el estado de la orden",
                response.errorMessage
              );
            } else {
              updatedDocument.status = status;
            }
          }

          loaderService.stop();
          dispatch(setShipmentCreateDocument(updatedDocument));
          dispatch(setShipmentCreatePhase(ShipmentCreatePhase.PAYMENT));
          return;
        }
        alertService.error("No se pudo anular el pago");
        loaderService.stop();
        return;
      }
      alertService.error("Este pago no se proceso con el terminal actual");
      loaderService.stop();
      return;
    }
    alertService.error(
      "La operación no tiene terminal asociado, no se puede anular"
    );
    loaderService.stop();
  };

  const onApproveReintegration = async (
    transaction: PaymentInterface,
    bankAccount?: BankAccountInterface
  ) => {
    loaderService.start();
    const response = await updatePayment({
      documentID: document!.documentID,
      paymentDetailID: transaction.paymentDetailID!,
      bankAccountID: bankAccount?.bankAccountID,
      sourceBankID: bankAccount?.bankID,
      statusID: PaymentStatusEnum.APPROVE,
      user: user!,
    });

    if (response.didError) {
      alertService.error("No se pudo aprobar el reintegro");
      loaderService.stop();
      return;
    }
    alertService.success("Reintegro aprobado con éxito");
    const documentResponse = await getDocument(document!.documentID);
    if (!documentResponse) {
      alertService.error("Error al obtener la orden");
      loaderService.stop();
      return;
    }

    const totalPaid = documentResponse.payments
      .filter(
        (p) =>
          p.status !== PaymentStatusEnum.CANCELED &&
          p.status !== PaymentStatusEnum.REJECT
      )
      .reduce((acc, payment) => acc + payment.amount, 0);
    const remaining = total - totalPaid;
    const status =
      (documentResponse.balanceAmount ?? remaining) < 0.01
        ? DocumentStatus.PAID
        : DocumentStatus.PARTIAL_PAID;

    let updatedDocument = {
      ...document!,
      payments: documentResponse.payments,
      total: documentResponse.total ?? total,
      status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    // Update document status
    if (document?.status !== status) {
      const response = await updateDocumentStatus(
        updatedDocument.documentID,
        status,
        undefined,
        user ?? undefined,
        status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
        updatedDocument.documentType
      );
      if (response.didError) {
        alertService.error(
          "Hubo un error actualizando el estado de la orden",
          response.errorMessage
        );
      } else {
        updatedDocument.status = status;
      }
    }

    loaderService.stop();
    dispatch(setShipmentCreateDocument(updatedDocument));
    dispatch(setShipmentCreatePhase(ShipmentCreatePhase.PAYMENT));
  };
  const onSaveProofOfPayment = async (
    file: File,
    retention: PaymentInterface,
    user: UserInterface,
    statusID: number,
    reference?: string,
    isModification?: boolean
  ) => {
    loaderService.start();
    const saved = await saveProofOfPayment(
      file,
      retention,
      user,
      statusID,
      reference,
      isModification,
      document?.documentID
    );

    if (saved.didError || !saved.model) {
      loaderService.stop();
      alertService.error(
        "Error al guardar el comprobante de pago",
        saved.errorMessage
      );
      return;
    }
    alertService.success("El comprobante se guardó exitosamente");

    const newPayments = payments.map((payment) => {
      if (payment !== retention) return payment;

      return {
        ...payment,
        proofOfPayment: saved.model!,
      };
    });

    const updatedDocument: DocumentInterface = {
      ...document!,
      payments: newPayments,
    };
    // const response = await saveDocument(
    //   updatedDocument,
    //   userBU?.code,
    //   user ?? undefined
    // );
    // if (!!response.didError) {
    //   alertService.error("Error al guardar la orden");
    //   return;
    // }

    loaderService.stop();
    dispatch(
      setShipmentCreateDocument({
        ...updatedDocument,
        //documentID: response.model!.id_solicitud,
      })
    );
  };

  const onDeleteProofOfPayment = async (retention: PaymentInterface) => {
    loaderService.start();

    const response = await deleteFileDO(retention.proofOfPayment!);

    if (response.didError || !response.model) {
      loaderService.stop();
      alertService.error(
        "Error al eliminar el comprobante de pago",
        response.errorMessage
      );
      return;
    }

    alertService.success("El comprobante se eliminó exitosamente");

    const newPayments = payments.map((payment) => {
      if (payment !== retention) return payment;

      return {
        ...payment,
        proofOfPayment: undefined,
      };
    });

    const update = await updatePayment({
      documentID: document?.documentID ?? retention.documentID!,
      paymentDetailID: retention.paymentDetailID!,
      statusID: PaymentStatusEnum.PENDING,
      user: user!,
    });

    if (update.didError) {
      alertService.error(
        "Error al eliminar el comprobante",
        update.errorMessage
      );
      loaderService.stop();
      return;
    }

    const updatedDocument: DocumentInterface = {
      ...document!,
      payments: newPayments,
    };

    loaderService.stop();
    dispatch(
      setShipmentCreateDocument({
        ...updatedDocument,
      })
    );
  };

  const onFinish = async () => {
    if (paymentMode !== PaymentMode.CONTADO || remaining < 0.01) {
      dispatch(setShipmentCreatePhase(ShipmentCreatePhase.RESUME));
    } else {
      dispatch(clearShipmentCreate());
    }
  };

  const createShipments = async (shipments: ShipmentInterface[]) => {
    let coupon: CouponDTO | null = null;

    const newShipments: ShipmentInterface[] = shipments.map((shipment) => {
      return {
        ...shipment!,
        status: ShipmentStatus.DRAFT,
      };
    });

    const shipment = newShipments[0];
    if (shipment.paymentMode === PaymentMode.CONTADO) {
      const date = moment();

      // Verify available coupons
      const coupons = await getAvailableCoupons(
        newShipments.length,
        shipment.buSource?.location.locationID ?? -1,
        shipment.paymentMode!,
        shipment.deliveryType,
        newShipments.reduce(
          (acc, shipment) => acc + +shipment.declaredValue!,
          0
        ),
        newShipments.reduce(
          (acc, shipment) => acc + +shipment.totalChargedWeight!,
          0
        ),
        date.tz("America/Caracas").format("YYYY-MM-DDTHH:mm:ss")
      );

      if (coupons.didError) {
        alertService.error(
          "Error al obtener los cupones",
          coupons.errorMessage
        );
      }

      if (coupons.model?.length) {
        coupon = coupons.model[0];

        const isBlocked = await verifyAccountBlackList(
          shipment.accountBillTo?.id!,
          coupon.couponID
        );
        console.log(isBlocked);

        if (
          !isBlocked.didError &&
          isBlocked.model !== null &&
          !isBlocked.model
        ) {
          // Add coupon items to shipments
          for (const shipment of newShipments) {
            if (shipment.id) {
              continue;
            }

            const hasSRM = shipment.items.some((item) => item.id === "20");
            const couponItems = coupon.items.filter(
              (item) => hasSRM || item.itemID !== 3020
            );
            shipment.items = [
              ...shipment.items,
              ...couponItems.map((item) => ({
                id: item.itemID.toString(),
                code: item.itemCode,
                name: item.itemName,
                order: 0,
                mandatory: false,
                isItemBase: item.isItemBase,
                rate: {
                  value: -coupon!.couponValue,
                  isPercentage: coupon!.isPercentage,
                  iva: 0,
                  ipostel: 0,
                  distance: 0,
                  tierCode: "",
                  tierName: "",
                },
              })),
            ];

            const itemsRate = await getShipmentRates(
              shipment.service ?? ShipmentService.STANDARD,
              shipment.paymentMode ?? PaymentMode.COD,
              shipment.deliveryType ?? DeliveryType.AT_OFFICE,
              shipment.buSource?.code ?? "",
              shipment.buConsignee?.code ?? "",
              shipment.accountBillTo?.id,
              shipment.buSource.location,
              shipment.consigneeAddress,
              shipment.items,
              shipment.pieces,
              shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
              new Date().toISOString(),
              applicationID,
              shipment.shipper.id ?? undefined
            );

            if (itemsRate.didError || !itemsRate.model) {
              alertService.error(
                "Error al obtener las tarifas de los descuentos",
                itemsRate.errorMessage
              );
            } else {
              shipment.items = itemsRate.model.items
                .filter(
                  (item) =>
                    (item.mandatory ||
                      shipment.items?.some((s) => s.id === item.id)) &&
                    item.id !== "30"
                )
                .sort((a, b) => a.order - b.order);

              const absoluteTotal = shipment.items
                .filter((item) => !item.rate.isPercentage)
                .reduce((total, item) => total + item.rate.value, 0);
              const percentageTotal = shipment.items
                .filter((item) => item.rate.isPercentage)
                .reduce((total, item) => total + item.rate.value, 0);
              const subtotal = absoluteTotal * (1 + percentageTotal / 100);
              const iva = shipment.items.reduce(
                (acc, item) => acc + item.rate.iva,
                0
              );
              const ipostel = shipment.items.reduce(
                (acc, item) => acc + item.rate.ipostel,
                0
              );

              shipment.total = subtotal + iva + ipostel;
            }
          }
        } else {
          coupon = null;
        }
      }
    }

    const savedShipments: ShipmentInterface[] = [];
    for (const shipment of newShipments) {
      // If shipment is already saved, don't save it again
      if (!!shipment.id) {
        savedShipments.push(shipment);
        continue;
      }

      const response = await saveShipment(
        shipment,
        user ?? undefined,
        applicationID
      );
      let id: string;

      if (!response || response.didError || !response.model) {
        alertService.error(`Error al guardar la guía`, response?.errorMessage);
        return;
      } else {
        id = response.model.shipmentHeaderID;
        savedShipments.push({
          ...shipment,
          id: response.model.shipmentHeaderID,
          shipmentNumber: response.model.shipmentNumber.toString(),
        });
      }

      if (coupon && id) {
        // Redeem coupon
        const redeemedCoupon = await redeemCoupon(
          coupon.couponID,
          id,
          shipment.buSource.code,
          shipment.buConsignee?.code ?? "",
          newShipments.reduce(
            (acc, shipment) => acc + +shipment.totalChargedWeight!,
            0
          ),
          new Date(),
          user?.userLogin ?? ""
        );

        if (redeemedCoupon.didError) {
          coupon = null;
          alertService.error(
            "Error al redimir el cupón",
            redeemedCoupon.errorMessage
          );
        }
      }
    }

    if (coupon) {
      dispatch(setShipmentCreateCoupon(coupon));
      const audio = new Audio("/audio/TEALCA Sonido Promoción.mp3");

      audio.volume = 0;
      audio.play();

      const is50 = coupon.couponValue === 50;
      const is100 = coupon.couponValue === 100;
      if (is100) {
        setOpenCoupon100GIF(true);
      } else if (is50) {
        setOpenCoupon50GIF(true);
      }

      let volume = 0;
      let peak = false;
      const interval = setInterval(() => {
        if (!peak && volume < 1) {
          volume += 0.025;
          audio.volume = Math.min(1, volume);
        } else if (!peak) {
          peak = true;
        } else if (peak && volume > 0) {
          volume -= 0.025;
          audio.volume = Math.max(0, volume);
        } else {
          clearInterval(interval);
        }
      }, 100);
    }

    return savedShipments;
  };

  const updateShipments = async (shipments: ShipmentInterface[]) => {
    const updatedShipments: ShipmentInterface[] = [];

    for (const shipment of shipments) {
      if (!!shipment.id) {
        const response = await updateShipment(
          shipment,
          true,
          true,
          user ?? undefined,
          applicationID
        );

        if (!response || response.didError || !response.model) {
          alertService.error(
            `Error al actualizar la guía`,
            response?.errorMessage
          );
          return;
        } else {
          updatedShipments.push({
            ...shipment,
          });
        }
      } else {
        const response = await saveShipment(
          shipment,
          user ?? undefined,
          applicationID
        );

        if (!response || response.didError || !response.model) {
          alertService.error(
            `Error al guardar la guía`,
            response?.errorMessage
          );
          return;
        } else {
          updatedShipments.push({
            ...shipment,
            id: response.model.shipmentHeaderID,
            shipmentNumber: response.model.shipmentNumber.toString(),
          });
        }
      }
    }

    return updatedShipments;
  };

  const handleShipmentDraftCreation = async () => {
    loaderService.start();

    const shipments = drafts as ShipmentInterface[];
    const savedShipments = shipments[0].id
      ? await updateShipments(shipments)
      : await createShipments(shipments);

    if (!savedShipments) {
      loaderService.stop();
      return;
    }

    window.scrollTo({ top: 0 });
    dispatch(setShipmentCreateList(savedShipments));
    dispatch(setShipmentCreateDrafts(savedShipments));
    dispatch(setShipmentCreateOwner(shipments[0].accountBillTo));
    dispatch(setShipmentCreatePhase(ShipmentCreatePhase.DOCUMENT));

    loaderService.stop();
  };

  const handleOwnerChange = async (owner: AccountInterface) => {
    if (document) {
      loaderService.start();
      const response = await updateDocumentAccountBillTo(
        document.documentID,
        owner,
        user ?? undefined
      );
      loaderService.stop();

      if (response.didError) {
        alertService.error(
          "Error al actualizar la cuenta a facturar",
          response.errorMessage
        );
        return;
      }
    }

    dispatch(setShipmentCreateOwner(owner));
  };

  useEffect(() => {
    const taxes: TaxInterface[] = [
      {
        name: "IVA",
        value: iva,
        type: "fixed",
      },
      {
        name: "Ipostel",
        value: ipostel,
        type: "fixed",
      },
    ];

    if (!!document?.igtfAmount) {
      taxes.push({
        name: "IGTF",
        value: document.igtfAmount,
        type: "fixed",
      });
    }

    dispatch(setShipmentCreateTaxes(taxes));
  }, [ipostel, iva, document, dispatch]);

  useEffect(() => {
    const buCode = businessUnit?.code;
    if (!buCode) return;

    const saveVtid = async () => {
      const buConfigResponse = await getVtid(buCode);
      if (buConfigResponse.didError) return;

      const vtid = buConfigResponse.model?.vtid;
      if (!vtid) return;
      setVtid(vtid);
    };

    saveVtid();
  }, []);

  useEffect(() => {
    updateDocument(document);
  }, []);

  return (
    <main className="lg:pl-72 pb-32">
      {/* Header */}
      <div className="py-8 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          {phase === ShipmentCreatePhase.SHIPMENTS
            ? "Crear Guía"
            : phase === ShipmentCreatePhase.DOCUMENT
            ? "Orden"
            : phase === ShipmentCreatePhase.PAYMENT
            ? "Pago"
            : "Resumen"}
        </header>
      </div>

      {/* Body */}
      <div>
        <HorizontalPadding paddingTop>
          {phase === ShipmentCreatePhase.SHIPMENTS && (
            <ShipmentFormList onSubmit={handleShipmentDraftCreation} />
          )}

          {(phase === ShipmentCreatePhase.DOCUMENT ||
            phase === ShipmentCreatePhase.PAYMENT) && (
            <div className="mt-5 flex flex-1 flex-col gap-6">
              {/* Header */}
              {phase === ShipmentCreatePhase.DOCUMENT && (
                <div className="flex flex-col">
                  <div>
                    <BackButton
                      handleOnRegret={() =>
                        dispatch(
                          setShipmentCreatePhase(ShipmentCreatePhase.SHIPMENTS)
                        )
                      }
                    />
                  </div>
                </div>
              )}

              {/* Show invoice owner */}
              {(paymentMode === PaymentMode.CONTADO ||
                paymentMode === PaymentMode.BOXOFFICE_CREDIT) &&
                (!document || !document.urlDocument) && (
                  <ShipmentOwner
                    owner={owner}
                    openCreationModal={setOpenCreationModal}
                    onSelectOwner={handleOwnerChange}
                  />
                )}

              {/* Show shipments resumen */}
              <div className={"pr-1"} style={{ flex: 1 }}>
                <ShipmentTable
                  showDetails
                  fields={[
                    ShipmentField.SHIPPER,
                    ShipmentField.CONSIGNEE,
                    ShipmentField.BU_SOURCE,
                    ShipmentField.DESTINATION,
                    ShipmentField.AMOUNT,
                  ]}
                  shipments={shipments.map((s) => s!)}
                />
              </div>

              {/* Show totals */}
              <PaymentTotal
                taxes={taxes}
                subTotal={subTotal}
                total={document?.total}
                paymentMode={paymentMode}
                baseRemaining={remaining}
                showEstimate={paymentMode === PaymentMode.COD}
              />

              {phase === ShipmentCreatePhase.DOCUMENT &&
                paymentMode !== PaymentMode.CONTADO &&
                (!!owner?.fiscalAddress ||
                  paymentMode === PaymentMode.CREDIT ||
                  paymentMode === PaymentMode.COD) && (
                  <div className="flex w-full justify-end">
                    <PrimaryButton
                      disabled={loading}
                      onClick={async () => {
                        loaderService.start();
                        const response = await onSave();
                        loaderService.stop();

                        if (!!response) {
                          onFinish();
                        }
                      }}
                    >
                      <ClipboardDocumentCheckIcon
                        className="h-5 w-5 flex-none text-white-400 mr-2"
                        aria-hidden="true"
                      />
                      Finalizar
                    </PrimaryButton>
                  </div>
                )}

              {phase === ShipmentCreatePhase.DOCUMENT &&
                paymentMode !== PaymentMode.CONTADO &&
                paymentMode !== PaymentMode.CREDIT &&
                paymentMode !== PaymentMode.COD &&
                !owner?.fiscalAddress && (
                  <div className="flex flex-col gap-4 bg-white rounded-lg border px-8 pb-6 pt-4">
                    <p className="text-gray-400">
                      Se necesita conocer la dirección fiscal de la persona a
                      facturar para poder generar la factura
                    </p>
                  </div>
                )}

              {/* Show pay methods */}
              {(paymentMode === PaymentMode.CONTADO || !!document) &&
                !!owner?.fiscalAddress && (
                  <>
                    <PaymentList
                      editable
                      items={items}
                      payments={payments}
                      remaining={remaining}
                      document={document}
                      owner={owner}
                      paymentMode={
                        document?.paymentMode ?? shipments[0].paymentMode
                      }
                      onPay={onPay}
                      onCancelRetention={onCancelRetention}
                      onCancelPayment={onCancelPayment}
                      onApproveReintegration={onApproveReintegration}
                      onSaveProofOfPayment={onSaveProofOfPayment}
                      onDeleteProofOfPayment={onDeleteProofOfPayment}
                    />

                    <div className="flex w-full justify-end">
                      {remaining >= 0.01 && (
                        <SecondaryButton
                          disabled={loading}
                          onClick={() => {
                            loaderService.start();
                            onSave().then((document) => {
                              loaderService.stop();
                              if (!!document) {
                                onFinish();
                              }
                            });
                          }}
                        >
                          Terminar más tarde
                        </SecondaryButton>
                      )}

                      {remaining < 0.01 && (
                        <PrimaryButton
                          disabled={loading}
                          onClick={async () => {
                            if (remaining > -0.01) {
                              loaderService.start();
                              await onSave();
                              onFinish();
                              loaderService.stop();
                            } else {
                              setOpenWarningModal(true);
                            }
                          }}
                        >
                          <ClipboardDocumentCheckIcon
                            className="h-5 w-5 flex-none text-white-400 mr-2"
                            aria-hidden="true"
                          />
                          Finalizar
                        </PrimaryButton>
                      )}
                    </div>
                  </>
                )}

              {/* The address of the owner of the invoice is required. */}
              {(paymentMode === PaymentMode.CONTADO || !!document) &&
                !owner?.fiscalAddress && (
                  <div className="flex flex-col gap-4 bg-white rounded-lg border px-8 pb-6 pt-4">
                    <p className="text-gray-400">
                      Se necesita conocer la dirección fiscal de la persona a
                      facturar para poder agregar pagos
                    </p>
                  </div>
                )}
            </div>
          )}

          {phase === ShipmentCreatePhase.RESUME && <ShipmentResume />}
        </HorizontalPadding>

        {/* Creation modal */}
        <ShipmentCreateClientModal
          openModal={openCreationModal}
          setOpenModal={setOpenCreationModal}
          setSelectedItem={(client) => {
            dispatch(setShipmentCreateOwner(client));
          }}
        />

        {/* Warning modal */}
        <Modal openModal={openWarningModal} setOpenModal={setOpenWarningModal}>
          <div
            className="flex flex-col items-center justify-center"
            style={{ maxWidth: "20rem" }}
          >
            <div className="flex flex-col items-center justify-center w-full">
              <ExclamationTriangleIcon className="w-32 h-32" />
            </div>
            <p className="mt-2 text-lg text-center text-gray-700">
              ¿Estás seguro que deseas finalizar la orden con un cambio
              pendiente?
            </p>
            <div className="mt-4 flex flex-row justify-center gap-12">
              <SecondaryButton
                className="px-4"
                onClick={() => setOpenWarningModal(false)}
              >
                Cancelar
              </SecondaryButton>

              <PrimaryButton
                className="px-4"
                disabled={loading}
                onClick={() => {
                  setOpenWarningModal(false);
                  onFinish();
                }}
              >
                Aceptar
              </PrimaryButton>
            </div>
          </div>
        </Modal>

        {/* Coupon video modal */}
        <Modal
          openModal={openCoupon100GIF}
          setOpenModal={setOpenCoupon100GIF}
          className="!p-0 overflow-hidden"
        >
          <img src="/video/Coupon100.gif" alt="Promoción" />
        </Modal>

        <Modal
          openModal={openCoupon50GIF}
          setOpenModal={setOpenCoupon50GIF}
          className="!p-0 overflow-hidden"
        >
          <img src="/video/Coupon50.gif" alt="Promoción" />
        </Modal>

        <Confetti
          className={classNames(
            !openCoupon100GIF && !openCoupon50GIF && "hidden"
          )}
        />
      </div>
    </main>
  );
};

export default ShipmentCreate;
